/*
 * The original location is public/print.css.
 * This must be copied to src/print.css on each change.
 */
#letter-body p {
  /* Use ckeditor's line height instead */
} 
#letter-body span {
  /* Use ckeditor's line height instead */
} 
#letter-body table { 
    page-break-inside:auto 
}

#letter-body tr    { 
    page-break-inside:avoid; 
    page-break-after:auto 
}
#letter-body thead { 
    display:table-header-group 
}
#letter-body tfoot { 
    display:table-footer-group 
}

#letter-body div { 
    page-break-inside:auto 
}

#letter-body p { 
    page-break-inside:auto; 
    line-height: 2em; 
}

#letter-body .cdl-page-break {
    display: block;
    width: 200%;
    margin: 2cm;
    margin-left: -3cm;
    background: #ecf0f5;
    height: 4cm;
}

#letter-body {
    font-size: 13pt;
}

#letter-body table {
    font-size: 13pt;
}

.letter-body-reset p {
    line-height: 1em !important;
}

.letter-body-reset span {
    line-height: 1em !important;
}

.letter-body-reset > p {
    line-height: 1em !important;
    margin-bottom:0px !important;
		margin-top:0px !important;
}

@media print {
    @page { 
        margin-left: 25mm; 
        margin-top: 20mm; 
        margin-right: 20mm; 
        margin-bottom: 20mm; 
        
    }

    .cdl-pagebreak {
        border: none !important;
        height: 0 !important;
        page-break-after: always;
    }

    #letter-body .cdl-page-break {
        width: 100%;
        background: transparent;
        border: none;
        margin: 0;
        page-break-after: always;
    }

    body {
        font-size: 13pt;
    }

    tabl {
        size: 13pt;
    }

    span {
        /* Use ckeditor's style height instead */
    }

}

/*
// These style must be present in these locations:
// public/ckeditor/plugins/cdllisttyle/cdlliststyle.css <--- this is the master copy
// public/print.css
// src/print.css
// start---------------------- */
ol.affix-style-close-bracket {
    counter-reset: list;
}
ol.affix-style-close-bracket > li {
    list-style: none;
}
ol.affix-style-close-bracket > li:before {
    content: counter(list) ") ";
    counter-increment: list;
}

ol.affix-style-close-bracket > li:before {
    content: counter(list) ") ";
    counter-increment: list;
}

ol.affix-style-close-bracket.list-lower-alpha > li:before {
    content: counter(list, lower-alpha) ") ";
    counter-increment: list;
}
ol.affix-style-close-bracket.list-upper-alpha > li:before {
    content: counter(list, upper-alpha) ") ";
    counter-increment: list;
}
ol.affix-style-close-bracket.list-lower-roman > li:before {
    content: counter(list, lower-roman) ") ";
    counter-increment: list;
}
ol.affix-style-close-bracket.list-upper-roman > li:before {
    content: counter(list, upper-roman) ") ";
    counter-increment: list;
}


ol.affix-style-in-brackets {
    counter-reset: list;
}
ol.affix-style-in-brackets > li {
    list-style: none;
}
ol.affix-style-in-brackets > li:before {
    content: "(" counter(list) ") ";
    counter-increment: list;
}
ol.affix-style-in-brackets.list-lower-alpha > li:before {
    content: "(" counter(list, lower-alpha) ") ";
    counter-increment: list;
}
ol.affix-style-in-brackets.list-upper-alpha > li:before {
    content: "(" counter(list, upper-alpha) ") ";
    counter-increment: list;
}
ol.affix-style-in-brackets.list-lower-roman > li:before {
    content: "(" counter(list, lower-roman) ") ";
    counter-increment: list;
}
ol.affix-style-in-brackets.list-upper-roman > li:before {
    content: "(" counter(list, upper-roman) ") ";
    counter-increment: list;
}


/* end---------------------------- */