.box-header {
    background-color: #e4e6e882 !important;
    color: black !important;
    font-size: 15px;
    font-weight: bold;
}

.presence {
    font-size: 13px;
    font-weight: 600;
}

.box-resence {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.box-resence:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.late-alert-2 {
    background: red;
    color: white;
    padding: 3px;
    margin-left: 10px;
    border-radius: 6px;
    font-weight: bold;
}

.late-alert-1 {
    background: #ffa500;
    color: white;
    padding: 3px;
    margin-left: 10px;
    border-radius: 6px;
    font-weight: bold;
}