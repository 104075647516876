.rapat-container {
  display: flex;
  width: 100%;
}

.rapat-stream-box {
  position: relative;
  display: flex;
}

.rapat-stream-box .video-wrapper {
  width: 100%;
  height: 100%;
  background: black;
}
.pointer {
  cursor: pointer;
}

.full-stream-box {
  position: absolute !important;
  width: 100% !important;
}

.remote-user .video-wrapper {
  width: 100%;
  height: 100%;
  background: black;
}

.rapat-stream-box video {
  width: 100%;
  height: 100%;

  margin-left: auto;
  margin-right: auto;
  display: block;
}

.rapat-stream-box .cam {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  background: white;
  opacity: 0.8;
}

.rapat-stream-box .mic {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background: white;
  opacity: 0.8;
}

.main-feed-space-enabled {
  width: 100%;
  position: relative;
  display: block;
}

.feeds-container {
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.remote-user {
  background: black;
  display: flex;
  position: relative;
}

.remote-user video {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.remote-user span {
  position: absolute;
  width: 100%;
  background: black;
  padding: 10px;
  color: white;
  bottom: 0px;
  left: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
}

.max1 {
  max-width: 100% !important;
}

.max2 {
  max-width: 50% !important;
}

.max3 {
  max-width: 33% !important;
}

.max4 {
  max-width: 33% !important;
}

.max4 {
  max-width: 33% !important;
}

.status-bar {
  display: block;
  width: 100%;
  padding: 20px;
  line-height: 2em;
  color: white;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: black;
}
