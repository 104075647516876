.loginBackground { 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.login-box-body {
  background:rgba(255,255,255,0.8) !important;
}

.bakti-logo{
  width:65px;
  margin-right:10px;
  margin-bottom:10px;
}

.surelintas-logo{
  margin-top:5px;
  margin-left:10px;
}

.login-logo-bottom{
  margin-top: 75px;
}