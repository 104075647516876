html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
}

button:disabled {
  opacity: 0.5;
}

.box {
  position: relative;
  border-radius: 5px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.box-header {
  border-radius: 5px;
}

.small-box {
  border-radius: 5px;
}

.btn {
  border-radius: 5px !important;
}

.input-group-btn > .btn {
  height:31px !important;
  padding:0.275rem 0.75rem
}

.nav > .nav-item > .nav-link {
  border-radius: 10px 10px 0px 0px !important;
  color: #00a65a;
}

.btn-primary {
  background-color: #00a65a;
  border-color: #00a65a;
}

.unread-count {
  border-radius: 5px;
}

.btn-primary:disabled {
  background-color: #00a65b54;
  border-color: #00a65a;
  color: black;
}

.btn-primary:hover {
  background-color: #00a65a !important;
  border-color: #00a65a !important;
}

.box.box-solid.box-primary > .box-header {
  background-color: #00a65b54 !important;
  
}

.box.box-solid.box-primary {
  border: 1px solid #00a65a;
}

.form-control {
  border-radius: 5px !important;
}


nav.pdf-viewer li {
 list-style-type: none;
 display: inline; 
}

nav.pdf-viewer .disabled {
  background-color: #777
}

@media screen and (max-width: 500px) {
  .chat-list {
    display: none;
  }
}